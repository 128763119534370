<template>
  <div class="chooser">
    <div class="row">
      <div class="col-md-12 chooser__fab">
        <label>Selecione a especialidade:</label>
        <span v-for="(fab, key) in fabricantes" :key="key" @click="selectedFab(key,fab,fab.enabled)" :class="{'disabled':!fab.enabled,'enabled':fab.enabled}">{{ fab.nome_especialidade }}</span>
       
      </div>
    </div>
    <div class="row chooser__cat" v-if="selectedCategorias.length > 0">
        <div class="col-md-12">
          <label>Selecione o produto:</label>
          <span v-for="(cat,key) in selectedCategorias" :key="key" @click="selectedCat(key)" :class="{'disabled':!cat.enabled,'enabled':cat.enabled}">{{cat.nome}}</span>
        </div>
    </div>
  </div>
</template>
<script>
import { getFabricantes } from "../services/fabricantes.services";
import { getCategorias } from "../services/categorias.services";
import qs from "qs";
export default {
  props: [],
  data() {
    return {
      fabricantes: [],
      categorias: [],
      selectedFabricante: "",
      selectedCategorias: [],
      catForquery:[]
    };
  },
  created() {
    this.getFab();
    
  },
  watch: {},
  methods: {
    async getFab() {
      const { data } = await getFabricantes();
      this.fabricantes = data;
      this.fabricantes = this.fabricantes.map(val => {
        val.enabled = false
        return val
      })
    },
    async getCategorias() {
      let fabricantes = this.fabricantes.map(val=>{
        return val.nome_especialidade
      })
      console.log(fabricantes)
      this.selectedCategorias = []
      let queryall = qs.stringify({
        _where: { 'especialidades.nome_especialidade': fabricantes[0] },
      });
      const { data } = await getCategorias(queryall);
      let cat_from_db = data
      
      for (const i in cat_from_db) {
        this.selectedCategorias.push({
          'nome':cat_from_db[i].nome_categoria,
          'id':cat_from_db[i]._id,
          'enabled':false
        })
      }
    },
    selectedFab(key,fab,status) {
      console.log(status)
      this.fabricantes = this.fabricantes.map(val=>{
        val.enabled = false
        return val
      })
      this.getCategorias()
      this.fabricantes[key].enabled = !status 
      this.$emit('querydb',{fab:fab,cat:this.catForquery})
    },
    selectedCat(key) {
      this.catForquery = []
      for(const i in this.selectedCategorias) {
         if(key == i) {
           this.selectedCategorias[i].enabled = !this.selectedCategorias[i].enabled
         }
         if(this.selectedCategorias[i].enabled) {
           this.catForquery.push(this.selectedCategorias[i].id)
         }
      } 
      this.$emit('querydb',{fab:this.selectedFabricante,cat:this.catForquery})
    }
  },
};
</script>
<style lang="scss" scoped>
label{
  display:block;
  padding-bottom:10px;
  margin-bottom:5px;
  border-bottom:1px solid #f9f9f9;
  font-weight:bold;
  color:linear-gradient(90deg,#206587 50%,#92b9d2 80%)!important;
}
.chooser {
  .chooser__fab {
    span {
      display:inline-block;
      font-size:14px;
      color:#206587;
      margin-right:20px;
      width:95%;
      padding:10px;
      padding-left:10px;
      padding-right:10px;
      border-radius:5px;
      background-image:none;
      cursor:pointer;
      font-family: 'Kumbh Sans', sans-serif;
      margin-bottom:10px;
      transition: all 0.5s;
      &.enabled {
         background-color:#206587 !important;
         color:#fff !important;
         padding-left:40px !important;
         font-weight:800;
         width:100% !important;
      }
      &.disabled {
         background-color:#e9ecef !important;
         
      }
      &:hover {
        padding-left:40px;
        opacity:0.8 !important;
        transition: all 0.5s;
        width:100%;
       
      }
    }
  }
  .chooser__cat {
    margin-top:30px;
    span {
      display:inline-block;
      font-size:14px;
      color:#206587;
      margin-right:20px;
      width:95%;
      padding:10px;
      padding-left:10px;
      padding-right:10px;
      border-radius:0px;
      cursor:pointer;
      font-family: 'Kumbh Sans', sans-serif;
      border-radius:5px;
      margin-bottom:10px;
transition: all 0.5s;
      &.enabled {
         background-color:#206587 !important;
         color:#fff !important;
         padding-left:40px !important;
         font-weight:800;
         width:100% !important;
      }
      &.disabled {
         background-color:#e9ecef !important;
         
      }
      &:hover {
        padding-left:40px;
        opacity:0.8 !important;
        transition: all 0.5s;
        width:100%;
       
      }
    }
  }
}
</style>